<div class="login">
    <div class="login__logo">
        <spy-logo class="login__logo-image"></spy-logo>
    </div>
    <spy-card [spyTitle]="title" titlePosition="center">
        <ng-content></ng-content>
    </spy-card>
</div>

<ng-template #title>
    <ng-content select="[title]"></ng-content>
</ng-template>
