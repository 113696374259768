document.addEventListener('DOMContentLoaded', function() {
    var loginWithOktaButton = document.getElementById('okta-login');
    if (loginWithOktaButton) {
        loginWithOktaButton.addEventListener('click', function() {
            let oktaAutherizationUrl = loginWithOktaButton.getAttribute('data-url');
            let emailValue = document.getElementById('security-merchant-portal-gui_username').value;
            if(oktaAutherizationUrl != '' && emailValue != '')
            {
                oktaAutherizationUrl = oktaAutherizationUrl + `&login_hint=${emailValue}`  ;
                window.location.href = oktaAutherizationUrl;
            }
            else
            {
                alert('Please fill Email.')
            }
        });
    }

});