import { ChangeDetectionStrategy, Component, ElementRef, AfterViewInit, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
    LoginLayoutComponent as CoreLoginLayoutComponent
} from '@mp/security-merchant-portal-gui';
import './login-okta.js';
@Component({
    selector: 'mp-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class LoginLayoutComponent extends CoreLoginLayoutComponent {


}
